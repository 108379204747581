<template>
  <div class="container">
    <h3 style="color: #b9bbbe">About me</h3>
    <h4 style="color: #b9bbbe">My <a style="color: #b9bbbe" v-bind:href="'https://github.com/mizumisuzu' ">{{"Github"}}</a> is mostly used just to host this website however, I would love to collaborate on a project with you. </h4>
    <h4 style="color: #b9bbbe">Add me on <a style="color: #b9bbbe" v-bind:href="'https://www.linkedin.com/in/sush-singidi-a78428273/' ">{{"Linkedin!"}}</a> If you prefer you can also contact me at sush.k.singidi at gmail.com.</h4>
    <h4 style="color: #b9bbbe" >My <a style="color: #b9bbbe" v-bind:href="'https://www.instagram.com/mizu_misuzuru/' ">{{"Insta  "}}</a> is just a repo of moments I captured growing up.</h4>
    <h2 style="color: #b9bbbe">Resume</h2>
    <iframe src="https://docs.google.com/document/d/e/2PACX-1vSPnVKqOwRIsCT1FR8UhE_ppMVatea4kx5WrH-IhzAluSoOYabqzLU1BulWn63C2nYFvOA2gphjpdnL/pub?embedded=true" width="500" height="750"></iframe>
  </div>
</template>


<style scoped>
.container {
  display: inline;
  flex-direction: column;
  height: 100%;
}

</style>
